import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AddKeyFilters, ClearSessionStore, RemoveFiltersFromStore, ResetFilters, SaveKeyFilters, SetFilters, SetPopState } from './filters.actions';
import {
  AttachmentsFilter,
  AdmissionDetailsFilter,
  AdmissionsFilters,
  BaseFilters,
  EmployeeAttachmentsFilters,
  EmployeeDocumentsFilters,
  EmployeeFilter,
  EmployeeNotesFilters,
  FILTERS,
  IncidentAttachmentsFilters,
  IncidentNotesFilters,
  IncidentsFilters,
  LessonPlanDetailsFilters,
  LessonsFilters,
  PatientAdmissionsFilters,
  PatientAttachmentsFilters,
  PatientIncidentsFilters,
  PatientNotesFilters,
  PatientRedFlagsFilters,
  PatientsFilters,
  PatientTimesheetsFilters,
  PER_PAGE_KEY,
  RedFlagsFilters,
  TimesheetsFilters,
  UsersEmployeesFilters,
  UsersFilters,
  MedicalAppDashboardFilters,
  MedicalAttachmentsFilters,
  MedicalNotesFilters,
  MedicalDocumentHistoryFilters,
  FiltersSavingState,
  PatientIncidentsReportFilters,
  PatientRedFlagsReportsFilters,
} from './filters.models';
import { FiltersKeyEnum } from '../../../shared/enums/filtersKey.enum';

export interface Filter<T> {
  model: T;
  dirty: boolean;
  status: string;
}

export interface FiltersStateModel {
  key: string[];
  popstate: boolean;
  admissionPeriod: Filter<AdmissionDetailsFilter>;
  admissionsAll: Filter<AdmissionsFilters>;
  admissions: Filter<AdmissionsFilters>;
  employees: Filter<EmployeeFilter>;
  patients: Filter<PatientsFilters>;
  incidents: Filter<IncidentsFilters>;
  allIncidents: Filter<IncidentsFilters>;
  newIncidents: Filter<IncidentsFilters>;
  timesheets: Filter<TimesheetsFilters>;
  redFlags: Filter<RedFlagsFilters>;
  redFlagAttachments: Filter<AttachmentsFilter>;
  employeeDocuments: Filter<EmployeeDocumentsFilters>;
  employeeNotes: Filter<EmployeeNotesFilters>;
  employeeAttachments: Filter<EmployeeAttachmentsFilters>;
  employeeAttachmentsApp: Filter<EmployeeAttachmentsFilters>;
  patientAdmissions: Filter<PatientAdmissionsFilters>;
  patientIncidents: Filter<PatientIncidentsFilters>;
  patientsIncidentReport: Filter<PatientIncidentsReportFilters>;
  patientAttachments: Filter<PatientAttachmentsFilters>;
  patientNotes: Filter<PatientNotesFilters>;
  patientTimesheets: Filter<PatientTimesheetsFilters>;
  patientRedFlagsReports: Filter<PatientRedFlagsReportsFilters>;
  patientRedFlags: Filter<PatientRedFlagsFilters>;
  incidentNotes: Filter<IncidentNotesFilters>;
  incidentAttachments: Filter<IncidentAttachmentsFilters>;
  admissionDetails: Filter<AdmissionDetailsFilter>;
  admissionAttachmentsApp: Filter<AttachmentsFilter>;
  hospitals: Filter<BaseFilters>;
  doctors: Filter<BaseFilters>;
  lessons: Filter<LessonsFilters>;
  lessonPlans: Filter<BaseFilters>;
  lessonPlanDetails: Filter<LessonPlanDetailsFilters>;
  lessonCategories: Filter<BaseFilters>;
  users: Filter<UsersFilters>;
  userEmployees: Filter<UsersEmployeesFilters>;
  roles: Filter<BaseFilters>;
  medicalDashboard: Filter<MedicalAppDashboardFilters>;
  medicalDocumentsHistory: Filter<MedicalDocumentHistoryFilters>;
  medicalAttachments: Filter<MedicalAttachmentsFilters>;
  medicalAttachmentsApp: Filter<MedicalAttachmentsFilters>;
  medicalNotes: Filter<MedicalNotesFilters>;
}

export const defaultState: FiltersStateModel = {
  key: [],
  popstate: false,
  admissionsAll: {
    model: FILTERS.admissionsAll,
    dirty: false,
    status: '',
  },
  admissions: {
    model: FILTERS.admissions,
    dirty: false,
    status: '',
  },
  admissionDetails: {
    model: FILTERS.admissionDetails,
    dirty: false,
    status: '',
  },
  admissionPeriod: {
    model: FILTERS.admissionDetails,
    dirty: false,
    status: '',
  },
  employees: {
    model: FILTERS.employees,
    dirty: false,
    status: '',
  },
  patients: {
    model: FILTERS.patients,
    dirty: false,
    status: '',
  },
  incidents: {
    model: FILTERS.incidents,
    dirty: false,
    status: '',
  },
  allIncidents: {
    model: FILTERS.incident_all,
    dirty: false,
    status: '',
  },
  newIncidents: {
    model: FILTERS.incident_new,
    dirty: false,
    status: '',
  },
  patientsIncidentReport: {
    model: FILTERS.patientsIncidentReport,
    dirty: false,
    status: '',
  },
  timesheets: {
    model: FILTERS.timesheets,
    dirty: false,
    status: '',
  },
  redFlags: {
    model: FILTERS.redFlags,
    dirty: false,
    status: '',
  },
  redFlagAttachments: {
    model: FILTERS.redFlagAttachments,
    dirty: false,
    status: '',
  },
  employeeDocuments: {
    model: FILTERS.employeeDocuments,
    dirty: false,
    status: '',
  },
  employeeNotes: {
    model: FILTERS.employeeNotes,
    dirty: false,
    status: '',
  },
  employeeAttachments: {
    model: FILTERS.employeeAttachments,
    dirty: false,
    status: '',
  },
  employeeAttachmentsApp: {
    model: FILTERS.employeeAttachmentsApp,
    dirty: false,
    status: '',
  },
  patientAdmissions: {
    model: FILTERS.patientAdmissions,
    dirty: false,
    status: '',
  },
  patientIncidents: {
    model: FILTERS.patientIncidents,
    dirty: false,
    status: '',
  },
  patientAttachments: {
    model: FILTERS.patientAttachments,
    dirty: false,
    status: '',
  },
  patientNotes: {
    model: FILTERS.patientNotes,
    dirty: false,
    status: '',
  },
  patientTimesheets: {
    model: FILTERS.patientTimesheets,
    dirty: false,
    status: '',
  },
  patientRedFlags: {
    model: FILTERS.patientRedFlags,
    dirty: false,
    status: '',
  },
  patientRedFlagsReports: {
    model: FILTERS.patientRedFlagsReports,
    dirty: false,
    status: '',
  },
  incidentNotes: {
    model: FILTERS.incidentNotes,
    dirty: false,
    status: '',
  },
  incidentAttachments: {
    model: FILTERS.incidentAttachments,
    dirty: false,
    status: '',
  },
  hospitals: {
    model: FILTERS.hospitals,
    dirty: false,
    status: '',
  },
  doctors: {
    model: FILTERS.doctors,
    dirty: false,
    status: '',
  },
  lessons: {
    model: FILTERS.lessons,
    dirty: false,
    status: '',
  },
  lessonPlans: {
    model: FILTERS.lessonPlans,
    dirty: false,
    status: '',
  },
  lessonPlanDetails: {
    model: FILTERS.lessonPlanDetails,
    dirty: false,
    status: '',
  },
  lessonCategories: {
    model: FILTERS.lessonCategories,
    dirty: false,
    status: '',
  },
  users: {
    model: FILTERS.users,
    dirty: false,
    status: '',
  },
  userEmployees: {
    model: FILTERS.userEmployees,
    dirty: false,
    status: '',
  },
  roles: {
    model: FILTERS.roles,
    dirty: false,
    status: '',
  },
  admissionAttachmentsApp: {
    model: FILTERS.admissionAttachmentsApp,
    dirty: false,
    status: '',
  },
  medicalDashboard: {
    model: FILTERS.medicalDashboard,
    dirty: false,
    status: '',
  },
  medicalDocumentsHistory: {
    model: FILTERS.medicalDocumentsHistory,
    dirty: false,
    status: '',
  },
  medicalAttachments: {
    model: FILTERS.medicalAttachments,
    dirty: false,
    status: '',
  },
  medicalAttachmentsApp: {
    model: FILTERS.medicalAttachmentsApp,
    dirty: false,
    status: '',
  },
  medicalNotes: {
    model: FILTERS.medicalNotes,
    dirty: false,
    status: '',
  },
};

@State({
  name: 'filters',
  defaults: defaultState,
})
@Injectable()
export class FiltersState {
  constructor(private store: Store) {}

  @Selector()
  static pageFilters(key: FiltersKeyEnum) {
    return createSelector([FiltersState], state => {
      return state.filters[key].model.page;
    });
  }

  @Selector()
  static keysFilter(state): FiltersSavingState {
    return {
      keys: state.key,
      popstate: state.popstate,
    };
  }

  @Selector()
  static filters(state) {
    return state.filters;
  }

  getPerPage(keyPerPage): string {
    return this.store.selectSnapshot(({ app }) => app.perPages?.find(per => per?.key === keyPerPage)?.perPage);
  }

  @Action(SaveKeyFilters)
  saveKeyFilters(ctx: StateContext<FiltersStateModel>, { key }: SaveKeyFilters): void {
    ctx.patchState({
      key: [key],
    });
  }

  @Action(AddKeyFilters)
  addKeyFilters(ctx: StateContext<FiltersStateModel>, { key }: SaveKeyFilters): void {
    const keys = ctx.getState().key;
    if (!keys.includes(key)) {
      ctx.patchState({
        key: [...keys, key],
      });
    }
  }

  @Action(ClearSessionStore)
  clearSessionStore(ctx: StateContext<FiltersStateModel>): void {
    ctx.patchState(defaultState);
    sessionStorage.clear();
  }

  @Action(SetPopState)
  setPopstate(ctx: StateContext<FiltersStateModel>, { popstate }): void {
    ctx.patchState({
      popstate,
    });
  }

  @Action(ResetFilters)
  resetFilters(ctx: StateContext<FiltersStateModel>, { key }: ResetFilters): void {
    const perPage = this.getPerPage(PER_PAGE_KEY[key]);
    const state = ctx.getState();
    ctx.patchState({
      [key]: {
        model: {
          ...FILTERS[key],
          perPage,
        },
        dirty: false,
        status: '',
      },
    });
    if (key.startsWith('admissionPeriod')) {
      ctx.patchState({
        [key]: {
          model: {
            ...FILTERS['admissionDetails'],
            perPage: this.getPerPage(PER_PAGE_KEY['admissionDetails']),
          },
          dirty: false,
          status: '',
        },
      });
    }
    ctx.patchState({
      key: state.key.filter(item => item !== key),
    });
  }

  @Action(RemoveFiltersFromStore)
  removeFiltersFromStore(ctx: StateContext<FiltersStateModel>, { prefix }: RemoveFiltersFromStore): void {
    const state = ctx.getState();
    removeFieldsStartingWith(state, prefix);
    ctx.setState(state);
  }

  @Action(SetFilters)
  setFilters(ctx: StateContext<FiltersStateModel>, { key, page }): void {
    ctx.patchState({
      [key]: {
        ...ctx.getState()[key],
        model: {
          ...ctx.getState()[key].model,
          page,
        },
      },
    });
  }
}

function removeFieldsStartingWith(obj: Record<string, any>, prefix: string): void {
  Object.keys(obj).forEach(key => {
    if (key.startsWith(prefix)) {
      delete obj[key];
    }
  });
}
